<template>
    <section class="blockElement space bg-white mkpCamp blogsData">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-5">
                    <button @click="goBack()" class="d-inline-flex align-items-center bold button zulu_btn rounded border-button">
                        <vue-feather type="chevron-left" class="me-2"></vue-feather>{{$t('blog.text3')}}
                    </button>
                </div>
                <div class="col-12" v-if="Object.keys(blogDetail).length">
                    <div class="card-body h-100">
                        <!-- <img class="d-block mb-3" src="images/icon/image.jfif" alt="Is the US stockmarket reversing?" /> -->
                        <h1 class="f-25 bold letter-space-0" v-if="blogDetail.title && blogDetail.title.rendered" v-html="blogDetail.title.rendered"></h1>
                        <p class="f-14">{{formatDate(blogDetail.date)}}</p>
                        <p v-html="blogDetail.content.rendered" v-if="blogDetail.content && blogDetail.content.rendered"></p>
                        <p v-else></p>
                    </div>
                </div>
                <div class="col-12 no-posts-section text-center" v-else>
                    <v-lazy-image src="/assets/images/noposts.png" alt="$t('blog.text5')" />
                    <p class="mt20">{{$t('blog.text4')}}</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import moment from "moment";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                showMore: false,
                blogDetail: {},
            };
        },
        methods: {
            formatDate(date) {
                if (date) {
                    return moment(date).format("DD MMM YYYY");
                } else {
                    return "N/A";
                }
            },
            goBack() {
                this.$router.go(-1);
            },
        },
        mounted() {
            this.store.getBlogDetail({}, true, this.$route.params.title).then((response) => {
                if (response.length) {
                    this.blogDetail = response[0];
                    document.title = this.blogDetail?.metadata?.rank_math_title[0] || '';
                    document
                        .querySelector('meta[name="description"]')
                        .setAttribute(
                            "content",
                            this.blogDetail?.metadata?.rank_math_description[0] || ''
                        );
                } else {
                    if (!this.$route.params.title.includes("-")) {
                        this.blogDetail = response;
                        document.title = this.blogDetail?.metadata?.rank_math_title[0] || '';
                        document
                            .querySelector('meta[name="description"]')
                            .setAttribute(
                                "content",
                                this.blogDetail?.metadata?.rank_math_description[0] || ''
                            );
                    } else {
                        this.blogDetail = {};
                    }
                }
            });
        },
    };
</script>
